import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import SEO from '../components/SEO';
import UpcomingEvents from '../components/UpcomingEvents';
import PageGrid from '../components/PageGrid';

const ArtistImage = styled.div`
  @media (max-width: 870px) {
    margin-right: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  @media (min-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: calc(var(--spacing) * 2);
  }
  display: grid;
  h3 {
    text-align: center;
    margin-bottom: calc(var(--spacing) * 2);
    padding-bottom: var(--spacing);
    display: block;
  }
`;

const Article = styled.article`
  max-width: 70ch;
  margin: 0 auto;
`;

const BackButton = styled.div`
  background: var(--white);
  border: 0;
  display: inline-flex;
  align-items: center;
  margin: 0 0 16px 2px;
  padding: 8px;
  span {
    padding-top: 4px;
  }
`;

export default function ArtistPage({ data: { artist, events } }) {
  const intl = useIntl();
  return (
    <>
      <SEO
        title={artist.artistName}
        image={artist?.artistImage?.asset?.fluid?.src}
        description={artist.description}
      />
      {/* <Link to="/artists/" title="Go back to artist list">
        <BackButton>
          <GrPrevious size="18" />
          <span>Go back</span>
        </BackButton>
      </Link> */}
      <PageGrid
        heading={artist.artistName}
        socialMedia={artist}
        socialMediaTitle={artist.artistName}
        footer={artist.email}
      >
        <Container>
          {artist.artistImage && (
            <ArtistImage>
              <Img fluid={artist.artistImage.asset.fluid} />
            </ArtistImage>
          )}
          <UpcomingEvents data={events} type="artist" />
        </Container>
        <Article>
          <h3>{intl.formatMessage({ id: 'biography' })}</h3>
          <p className="body-copy">{artist.bio}</p>
        </Article>
      </PageGrid>
    </>
  );
}

export const query = graphql`
  query($slug: String, $id: String) {
    artist: sanityArtist(slug: { current: { eq: $slug } }) {
      description
      website
      artistName
      bio
      email
      social {
        facebook
        instagram
        spotify
        twitter
      }
      artistImage {
        asset {
          fluid(maxWidth: 600) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    events: allSanityEvent(
      filter: { eventArtists: { elemMatch: { _id: { eq: $id } } } }
      limit: 4
    ) {
      nodes {
        id
        slug {
          current
        }
        location {
          venueName
        }
        eventTitle
        eventStartDate
      }
    }
  }
`;
